<template>
	<div class="content" >
		<list-header :loading="loading" />
		<div class="content--loading" v-if="loading">
			<filling-circle color="#057DCD" :size="42" />
		</div>
		<div class="content__list"  v-else-if="error">
			<span>Nothing to Display</span>
		</div>
		<div class="content__list"  v-else>
			<list-table :header="header" :list="items" :next="next" @load-more-data="loadData" :loadingNext="loadingNext"/>
			<router-view />
		</div>
	</div>
</template>

<script>
import ListHeader from '@/components/ListHeader.vue'
import FillingCircle from '@/components/Loaders/FillingCircle.vue'
import ListTable from '@/components/Table.vue'
export default {
	components: {FillingCircle,ListHeader,ListTable},
	name: 'List',
	data(){
		return {
			loading:true,
			loadingNext: false,
			next:null,
			pollingTimer:null,
		}
	},
	computed:{
		
		header(){
			return this.$route.meta['RESPONSE_DATA_FILTER'].header
		},
		id(){
			return this.$route.params.id
		},
		loadType(){
			return this.$route.meta['REQUESTS'].load
		},
		isPolling(){
			return this.$route.meta['REQUESTS'].isPolling
		},
		
		getBranchId(){
			
			return this.$store.state.branchId
		},
		state(){
			return this.$route.meta['OPTIONS'].state
		},
		headerSearchQuery(){
			return this.$store.state.headerSearchQuery.toLowerCase()
		},

		items(){
			let loadedState = this.$store.state[this.state.name]
			let loadedData = loadedState[this.state.data]
			let name
			
			if (loadedData[0]) name = loadedData[0].name ? 'name' : 'reference'
			
			let filterLoadedData = loadedData.filter( data => data[name].toLowerCase().includes(this.headerSearchQuery) )
			return filterLoadedData
		},
		error(){
			return this.$store.state.error
		},
	},
	methods:{
		setBranchId(){
			this.$store.commit({type:'setBranchId',branchId:this.id})
		},


		loadData(){

			this.loadingNext = true

			this.$store.dispatch({type:this.loadType,id:this.id,next:this.next}).then((response)=>{
				
				let next  = response.next
				if (next) this.next = next.split('cursor=').pop()
				else this.next = next

				console.log('unset branch id', this.id)
				if(!this.getBranchId && this.id) this.setBranchId()
				if (this.isPolling) this.pollingTimer = setTimeout(this.loadData,15000)

				
				this.loading = false
				this.loadingNext = false
			})
			.catch((error)=>{
				this.loading = false
				this.loadingNext = false
			
				console.log(error)
			})
		},
	},

	beforeUnmount () {
		
		clearTimeout(this.pollingTimer)
	},

	mounted(){
		this.loadData()
	}

	
}
</script>